import { Company } from '@dougs/company/dto';
import { Spouse } from '@dougs/user/dto';
import { Partner } from '../dto';

const getDefaults = (): Partner => ({
  id: 1,
  fullName: 'fullName',
  isAssociate: false,
  isCeo: false,
  isDirector: false,
  isEmployee: false,
  isVendor: false,
  isClient: false,
  isAffiliate: false,
  companyId: 1,
  createdAt: new Date('2021-12-07'),
  naturalPersonId: 1,
  legalPersonId: -1,
  legalPerson: {} as Company,
  contracts: [],
  sejournProofs: [],
  workLicenceProofs: [],
  naturalPerson: {
    alternateAvatarUrl: '',
    fullName: '',
    avatarUrl: '',
    email: '',
    normalizedPhones: [],
    firstName: '',
    lastName: '',
    phone: '',
    isMan: false,
    workPhone: '',
    identityProof: [],
    spouse: {} as Spouse,
    initials: '',
  },
});

export const getPartnerMock = (p?: Partial<Partner>): Partner => ({
  ...getDefaults(),
  ...p,
});
