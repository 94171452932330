<div *ngIf="roleFields" [formGroup]="formPartnerService.formGroup">
  <dougs-employee-payslips *ngIf="partner.payrolls?.length" [partner]="partner"></dougs-employee-payslips>
  <h5 class="mb-16 mt-8 title">Poste occupé</h5>
  <ng-container formGroupName="metadata">
    <ng-container formGroupName="employee">
      <ng-container formGroupName="social">
        <div class="mb-16">
          <dougs-field [field]="roleFields['metadata.employee.social.jobName']" formControlName="jobName"></dougs-field>
        </div>
        <dougs-employee-form-workplace
          *ngIf="roleFields['metadata.employee.social.workPlace.address']"
          [roleFields]="roleFields"
        ></dougs-employee-form-workplace>
        <div class="mb-16">
          <dougs-field
            [field]="roleFields['metadata.employee.social.statusJob']"
            formControlName="statusJob"
          ></dougs-field>
        </div>
        <dougs-employee-form-contracts [partner]="partner" [roleFields]="roleFields"></dougs-employee-form-contracts>
        <div *ngIf="roleFields['metadata.employee.social.monthlyIncome']?.isAvailable" class="mb-16">
          <dougs-divider></dougs-divider>
        </div>
        <div class="mb-16">
          <dougs-field
            [field]="roleFields['metadata.employee.social.monthlyIncome']"
            formControlName="monthlyIncome"
          ></dougs-field>
        </div>
        <div class="mb-16">
          <dougs-field
            [field]="roleFields['metadata.employee.social.monthlyIncomeType']"
            formControlName="monthlyIncomeType"
          ></dougs-field>
        </div>
        <div class="mb-16">
          <dougs-field
            [field]="roleFields['metadata.employee.social.paymentMethod']"
            formControlName="paymentMethod"
          ></dougs-field>
        </div>
        <div class="mb-16">
          <dougs-field
            [field]="roleFields['metadata.employee.social.cooperativeDispensed']"
            formControlName="cooperativeDispensed"
          ></dougs-field>
        </div>
        <div class="mb-16">
          <dougs-field
            [field]="roleFields['metadata.employee.social.cooperativeBeneficiaries']"
            formControlName="cooperativeBeneficiaries"
          ></dougs-field>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
  <div class="mb-16">
    <dougs-field
      [field]="roleFields['cooperativeDispense']"
      formControlName="cooperativeDispense"
      (uploadFile)="
        partnerComponentService.uploadFiles(
          partner,
          roleFields['cooperativeDispense']?.input?.fileType,
          'cooperativeDispense',
          $event
        )
      "
      (deleteFile)="
        partnerComponentService.deleteFiles(
          partner,
          roleFields['cooperativeDispense']?.input?.fileType,
          'cooperativeDispense',
          $event,
          roleFields['cooperativeDispense']?.input?.allowMultiple
        )
      "
    ></dougs-field>
  </div>
  <div class="mb-16" *ngIf="roleFields['recognitionOfDisabledWorkers']?.isAvailable">
    <dougs-field
      [field]="roleFields['recognitionOfDisabledWorkers']"
      formControlName="recognitionOfDisabledWorkers"
      (uploadFile)="
        partnerComponentService.uploadFiles(
          partner,
          roleFields['recognitionOfDisabledWorkers']?.input?.fileType,
          'recognitionOfDisabledWorkers',
          $event
        )
      "
      (deleteFile)="
        partnerComponentService.deleteFiles(
          partner,
          roleFields['recognitionOfDisabledWorkers']?.input?.fileType,
          'recognitionOfDisabledWorkers',
          $event,
          roleFields['recognitionOfDisabledWorkers']?.input?.allowMultiple
        )
      "
    ></dougs-field>
  </div>
  <ng-container formGroupName="metadata">
    <ng-container formGroupName="employee">
      <ng-container formGroupName="social">
        <div class="mb-16">
          <dougs-field
            [field]="roleFields['metadata.employee.social.isRegistered']"
            formControlName="isRegistered"
          ></dougs-field>
        </div>
        <div class="mb-16">
          <dougs-field
            [field]="roleFields['metadata.employee.social.exonerationOtherValue']"
            formControlName="exonerationOtherValue"
          ></dougs-field>
        </div>
        <div class="mb-16">
          <dougs-field
            [field]="roleFields['metadata.employee.social.hasAccre']"
            formControlName="hasAccre"
          ></dougs-field>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
  <div class="mb-16">
    <dougs-field
      [field]="roleFields['accres']"
      formControlName="accres"
      (uploadFile)="
        partnerComponentService.uploadFiles(
          partner,
          roleFields['accres']?.input?.singularDocumentType,
          'accres',
          $event
        )
      "
      (deleteFile)="
        partnerComponentService.deleteFiles(
          partner,
          roleFields['accres']?.input?.singularDocumentType,
          'accres',
          $event,
          roleFields['accres']?.input?.allowMultiple
        )
      "
    ></dougs-field>
  </div>
  <ng-container formGroupName="metadata">
    <ng-container formGroupName="employee">
      <ng-container formGroupName="social">
        <dougs-employee-form-workdays [roleFields]="roleFields"></dougs-employee-form-workdays>
        <div class="mb-16" *ngIf="roleFields['metadata.employee.social.weekDuration']?.isAvailable">
          <dougs-field
            [field]="roleFields['metadata.employee.social.weekDuration']"
            formControlName="weekDuration"
          ></dougs-field>
        </div>
        <div class="mb-16" *ngIf="roleFields['metadata.employee.social.monthDuration']?.isAvailable">
          <dougs-field
            [field]="roleFields['metadata.employee.social.monthDuration']"
            formControlName="monthDuration"
          ></dougs-field>
        </div>
        <div
          class="mb-16"
          *ngIf="roleFields['metadata.employee.social.minimumWeekDurationExonerationReason']?.isAvailable"
        >
          <dougs-field
            [field]="roleFields['metadata.employee.social.minimumWeekDurationExonerationReason']"
            formControlName="minimumWeekDurationExonerationReason"
          ></dougs-field>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="roleFields['minimumWeekDurationDerogations']?.isAvailable">
    <div class="mb-16">
      <dougs-divider></dougs-divider>
    </div>
    <div class="mb-16">
      <dougs-field
        [field]="roleFields['minimumWeekDurationDerogations']"
        (uploadFile)="
          partnerComponentService.uploadFiles(
            partner,
            roleFields['minimumWeekDurationDerogations']?.input?.singularDocumentType,
            'minimumWeekDurationDerogations',
            $event
          )
        "
        (deleteFile)="
          partnerComponentService.deleteFiles(
            partner,
            roleFields['minimumWeekDurationDerogations']?.input?.singularDocumentType,
            'minimumWeekDurationDerogations',
            $event,
            roleFields['minimumWeekDurationDerogations']?.input?.allowMultiple
          )
        "
        formControlName="minimumWeekDurationDerogations"
      ></dougs-field>
    </div>
  </ng-container>
  <ng-container *ngIf="showEmployeeInformations">
    <h5 class="title mt-8 mb-16">Information sur le salarié</h5>
    <ng-container formGroupName="metadata">
      <ng-container formGroupName="employee">
        <ng-container formGroupName="social">
          <div class="mb-16">
            <dougs-field
              [field]="roleFields['metadata.employee.social.familyPersonCount']"
              formControlName="familyPersonCount"
            ></dougs-field>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-container formGroupName="naturalPerson">
      <div class="mb-16">
        <dougs-field
          [field]="naturalPersonFields['socialSecurityProof']"
          (uploadFile)="
            naturalPersonComponentService.uploadFiles(
              partner,
              naturalPersonFields['socialSecurityProof']?.input?.fileType,
              'socialSecurityProof',
              $event
            )
          "
          (deleteFile)="
            naturalPersonComponentService.deleteFiles(
              partner,
              naturalPersonFields['socialSecurityProof']?.input?.fileType,
              'socialSecurityProof',
              $event,
              naturalPersonFields['socialSecurityProof']?.input?.allowMultiple
            )
          "
          formControlName="socialSecurityProof"
        ></dougs-field>
      </div>
    </ng-container>
    <div class="mb-16">
      <dougs-field
        [field]="roleFields['sejournProofs']"
        formControlName="sejournProofs"
        (uploadFile)="
          partnerComponentService.uploadFiles(
            partner,
            roleFields['sejournProofs']?.input?.singularDocumentType,
            'sejournProofs',
            $event
          )
        "
        (deleteFile)="
          partnerComponentService.deleteFiles(
            partner,
            roleFields['sejournProofs']?.input?.singularDocumentType,
            'sejournProofs',
            $event,
            roleFields['sejournProofs']?.input?.allowMultiple
          )
        "
      ></dougs-field>
    </div>
    <div class="mb-16">
      <dougs-field
        [field]="roleFields['workLicenceProofs']"
        formControlName="workLicenceProofs"
        (uploadFile)="
          partnerComponentService.uploadFiles(
            partner,
            roleFields['workLicenceProofs']?.input?.singularDocumentType,
            'workLicenceProofs',
            $event
          )
        "
        (deleteFile)="
          partnerComponentService.deleteFiles(
            partner,
            roleFields['workLicenceProofs']?.input?.singularDocumentType,
            'workLicenceProofs',
            $event,
            roleFields['workLicenceProofs']?.input?.allowMultiple
          )
        "
      ></dougs-field>
    </div>
  </ng-container>
  <ng-container *ngIf="partner?.isEmployee">
    <dougs-divider class="mb-16"></dougs-divider>
    <p class="tiny color-gray-350 mb-16">
      Voulez-vous supprimer ce rôle ?
      <a class="color-error" href="#" (click)="deleteEmployeeRole($event)">Supprimer</a>
    </p>
    <dougs-divider class="mb-16"></dougs-divider>
  </ng-container>
  <div class="button-container">
    <dougs-button color="secondary mr-8" size="small" (click)="resetForm($event)">
      {{ formPartnerService.metadataEmployeeSocialFormGroup?.dirty ? 'Annuler' : 'Fermer' }}
    </dougs-button>
    <dougs-button
      *ngIf="formPartnerService?.metadataEmployeeSocialFormGroup?.dirty"
      color="primary"
      size="small"
      (click)="saveAndClose($event)"
    >
      Enregistrer
    </dougs-button>
  </div>
</div>
